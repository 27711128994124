import styled from 'styled-components'
import Banner from './components/Banner'
import Introduce from './components/Introduce'
import TabTable from './components/TabTable'
import NftCarousel from './components/NftCarousel'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0 46px 0;
  width: 100%;
`

const Home = () => {
  return (
    <Wrapper>
      <Banner />
      <NftCarousel />
      <Introduce />
      <TabTable />
    </Wrapper>
  )
}

export default Home
