import { gql } from 'graphql-request'
import moment from 'moment-timezone'
import useSWR, { SWRConfiguration } from 'swr'
import { foggyClient } from 'utils/graphql'
import SWR_KEY from './constant'

const TICKET_LIST = gql`
  query MyQuery($startDate: String, $endDate: String ,$first: Int, $skip: Int) {
    buyTickets(
      where: {and: [{blockTimestamp_gte: $startDate, blockTimestamp_lte: $endDate}]}
      first: $first
      skip: $skip
    ) {
      id
    }
  }
`

const getBuyTicketList = async (startDate: string, endDate: string): Promise<{ data?: any; error: boolean }> => {
  try {
    const first = 1000
    let skip = 0
    let data: any | undefined

    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const dataRs = await foggyClient.request(TICKET_LIST, { startDate, endDate, first: Number(first), skip: Number(skip) })

      if (!data) {
        data = {
          buyTickets: [],
        }
      }

      if (!dataRs || !dataRs?.buyTickets || !dataRs?.buyTickets?.length) {
        break
      }

      data.buyTickets.push(...dataRs.buyTickets)

      skip += 1000
    }

    return {
      data: data?.buyTickets,
      error: false,
    }
  } catch (error) {
    console.error('getBuyTicketList', error)
    return { error: true }
  }
}

export const useGetBuyTicketReportSwr = (options?: SWRConfiguration) => {
  const startDate = moment.utc().startOf("date").unix()
  const endDate = moment.utc().endOf("date").unix()

  const fetchData = async () => getBuyTicketList(startDate.toString(), endDate.toString()).then((res) => res?.data)
 

  return useSWR([SWR_KEY.GET_BUY_TICKET_REPORT, startDate, endDate], fetchData, {
    revalidateOnFocus: false,
    ...options,
  })
}
