import { Carousel, Image } from 'antd'
import { useTranslation } from 'contexts/Localization'
import { useGetBuyTicketReportSwr } from 'hooks/swr/useGetBuyTicketReportSwr'

const settings = {
  dots: false,
  infinite: true,
  speed: 10000,
  slidesToShow: 7,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: 'linear',
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
}

const NftCarousel = () => {
  const { t } = useTranslation()
  const { data } = useGetBuyTicketReportSwr()
  
  return (
    <div>
      <p className="lg:text-[24px] md:text-[18px] text-sm text-center font-semibold mb-6 px-4">
        {t("Don't miss your chance!")} <span className="font-bold text-[#A6CD0A]">{!data ? '--' : data?.length}</span>{' '}
        {t("tickets have been sold today!")}
      </p>

      <Carousel {...settings}>
        <Image preview={false} src="/images/home/carousel-1.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-2.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-1.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-2.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-1.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-2.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-1.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-2.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-1.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-2.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-1.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-2.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-1.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-2.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-1.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-2.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-1.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-2.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-1.png" className="md:px-4 px-2" />
        <Image preview={false} src="/images/home/carousel-2.png" className="md:px-4 px-2" />
      </Carousel>
    </div>
  )
}

export default NftCarousel
